<template>
  <Loading v-if="isLoading" />
  <div v-else class="add-notification">
    <b-card>
      <h2 class="m-0 page-title">{{ $t("notifications.notifications") }}</h2>
      <hr />
      <div class="message-info">
        <h3 class="sub-title mb-3">
          {{ $t("notifications.message_information") }}
        </h3>
        <validation-observer ref="replyValidation">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="12">
                <b-form-group
                  :label="$t('notifications.title')"
                  label-for="Name"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('notifications.title')"
                    rules="required"
                  >
                    <b-form-input
                      type="text"
                      v-model="form_data.title"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                      class="custom-input"
                    />
                    <small class="text-danger">{{
                      errors[0]
                        ? $helpers.translateValidations(
                            errors[0],
                            $t("notifications.title")
                          )
                        : errors[0]
                    }}</small></validation-provider
                  >
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group :label="$t('notifications.body')">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('notifications.body')"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="form_data.body"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                    />
                    <small class="text-danger">{{
                      errors[0]
                        ? $helpers.translateValidations(
                            errors[0],
                            $t("notifications.body")
                          )
                        : errors[0]
                    }}</small></validation-provider
                  >
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('notifications.type')">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('notifications.type')"
                    rules="required"
                  >
                    <b-form-select
                      v-model="form_data.user_type"
                      :options="user_type"
                      :state="errors.length > 0 ? false : null"
                    >
                    </b-form-select>
                    <small class="text-danger">{{
                      errors[0]
                        ? $helpers.translateValidations(
                            errors[0],
                            $t("notifications.type")
                          )
                        : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('notifications.send_through')"
                  class="send-through"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('notifications.send_through')"
                    rules="required"
                  >
                    <div class="send-through-checkbox">
                      <!-- <b-form-checkbox
                      id="sms"
                      v-model="form_data.method"
                      name="sms"
                      value="sms"
                    >
                      {{ $t('notifications.sms') }}
                    </b-form-checkbox> -->

                      <b-form-checkbox
                        id="email"
                        v-model="form_data.via"
                        name="via"
                        value="mail"
                      >
                        {{ $t("notifications.email") }}
                      </b-form-checkbox>
                      <b-form-checkbox
                        id="system"
                        v-model="form_data.via"
                        name="via"
                        value="database"
                      >
                        {{ $t("notifications.system") }}
                      </b-form-checkbox>
                    </div>
                    <small class="text-danger">{{
                      errors[0]
                        ? $helpers.translateValidations(
                            errors[0],
                            $t("notifications.send_through")
                          )
                        : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-button
              type="submit"
              variant="primary"
              block
              @click="sendNotification"
              class="primary-btn mt-3"
              :disabled="isPending"
            >
              <div v-if="isPending" class="loading_" data-loading="light" />
              <span v-else>{{ $t("notifications.send_notification") }}</span>
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormSelect,
  BFormTextarea,
  BForm,
  BFormGroup,
  BButton,
  BFormCheckbox,
  BFormInput,
} from "bootstrap-vue";
import Loading from "@/components/shared/loading/loading.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { email, required } from "@core/utils/validations/validations";

export default {
  name: "SendNotification",

  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    Loading,
    BFormSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormInput,
  },
  data() {
    return {
      isPending: null,
      isLoading: true,
      overlayLoading: false,
      current_id: null,
      form_data: {
        title: null,
        body: null,
        via: [],
        user_type: null,
      },
      user_type: [
        {
          value: null,
          text: this.$t("notifications.please_select_the_user_type"),
          disabled: true,
        },
        {
          value: "all",
          text: this.$t("notifications.all"),
        },
        {
          value: "admins",
          text: this.$t("notifications.admins"),
        },
        {
          value: "vendors",
          text: this.$t("notifications.vendors"),
        },
        {
          value: "delivery_boys",
          text: this.$t("notifications.delivery_boys"),
        },
        {
          value: "users",
          text: this.$t("notifications.users"),
        },
      ],
    };
  },
  created() {
    this.isLoading = false;
  },
  methods: {
    sendNotification() {
      this.$refs.replyValidation.validate().then((success) => {
        if (success) {
          this.isPending = true;
          const formData = new FormData();
          for (const key in this.form_data) {
            if (key === "via") {
              for (const item in this.form_data[key]) {
                formData.append(`via[${item}]`, this.form_data[key][item]);
              }
            } else {
              formData.append(key, this.form_data[key]);
            }
          }

          this.$http
            .post("admin/notifications/broadcast", formData)
            .then((res) => {
              if (res.status === 200) {
                this.$helpers.makeToast(
                  "success",
                  res.data.message,
                  res.data.message
                );
                this.isPending = false;
                this.$router.push({ name: "notifications" });
              }
            })
            .catch((err) => {
              this.$helpers.handleError(err);
              this.isPending = false;
            });
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "./index";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
