var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('Loading'):_c('div',{staticClass:"add-notification"},[_c('b-card',[_c('h2',{staticClass:"m-0 page-title"},[_vm._v(_vm._s(_vm.$t("notifications.notifications")))]),_c('hr'),_c('div',{staticClass:"message-info"},[_c('h3',{staticClass:"sub-title mb-3"},[_vm._v(" "+_vm._s(_vm.$t("notifications.message_information"))+" ")]),_c('validation-observer',{ref:"replyValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('notifications.title'),"label-for":"Name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('notifications.title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"custom-input",attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.form_data.title),callback:function ($$v) {_vm.$set(_vm.form_data, "title", $$v)},expression:"form_data.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$helpers.translateValidations( errors[0], _vm.$t("notifications.title") ) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('notifications.body')}},[_c('validation-provider',{attrs:{"name":_vm.$t('notifications.body'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.form_data.body),callback:function ($$v) {_vm.$set(_vm.form_data, "body", $$v)},expression:"form_data.body"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$helpers.translateValidations( errors[0], _vm.$t("notifications.body") ) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('notifications.type')}},[_c('validation-provider',{attrs:{"name":_vm.$t('notifications.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.user_type,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.user_type),callback:function ($$v) {_vm.$set(_vm.form_data, "user_type", $$v)},expression:"form_data.user_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$helpers.translateValidations( errors[0], _vm.$t("notifications.type") ) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"send-through",attrs:{"label":_vm.$t('notifications.send_through')}},[_c('validation-provider',{attrs:{"name":_vm.$t('notifications.send_through'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"send-through-checkbox"},[_c('b-form-checkbox',{attrs:{"id":"email","name":"via","value":"mail"},model:{value:(_vm.form_data.via),callback:function ($$v) {_vm.$set(_vm.form_data, "via", $$v)},expression:"form_data.via"}},[_vm._v(" "+_vm._s(_vm.$t("notifications.email"))+" ")]),_c('b-form-checkbox',{attrs:{"id":"system","name":"via","value":"database"},model:{value:(_vm.form_data.via),callback:function ($$v) {_vm.$set(_vm.form_data, "via", $$v)},expression:"form_data.via"}},[_vm._v(" "+_vm._s(_vm.$t("notifications.system"))+" ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$helpers.translateValidations( errors[0], _vm.$t("notifications.send_through") ) : errors[0]))])]}}])})],1)],1)],1),_c('b-button',{staticClass:"primary-btn mt-3",attrs:{"type":"submit","variant":"primary","block":"","disabled":_vm.isPending},on:{"click":_vm.sendNotification}},[(_vm.isPending)?_c('div',{staticClass:"loading_",attrs:{"data-loading":"light"}}):_c('span',[_vm._v(_vm._s(_vm.$t("notifications.send_notification")))])])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }